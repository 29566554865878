<template>
  <footer class="m-auto flex h-16 w-full min-w-[80rem] max-w-[120rem] items-center justify-between px-10 py-2">
    <p class="text-xs font-normal text-slate-600">Copyright © datamaker. 2025. All rights reserved.</p>
    <FormKit
      type="select"
      :options="[
        { label: '한국어', value: 'ko' },
        { label: '영어', value: 'en' }
      ]"
      outer-class="!mb-0 w-32"
      value="ko"
      input-class="rounded-lg text-sm py-1 border-slate-300"
      disabled
      place-top />
  </footer>
</template>

<script setup lang="ts"></script>
