<template>
  <Accordion v-bind="props" v-model="currentAccordionValue" collapsible>
    <template #trigger="{ index }">
      <DirectoryNavigationLink
        v-if="props.items[index]?.trigger?.to"
        v-bind="props.items[index]?.trigger"
        @click="currentAccordionValue = ''" />
    </template>
    <template #content="{ index }">
      <template v-for="(childItem, childIndex) in props.items[index]?.children" :key="`childItem-${childIndex}`">
        <DirectoryNavigationLink v-if="childItem" v-bind="childItem" />
      </template>
    </template>
  </Accordion>
</template>

<script setup lang="ts">
import type { DirectoryNavigationAccordionProps } from './types'

const props = defineProps<DirectoryNavigationAccordionProps>()

const currentAccordionValue = ref<string>(
  props.items.find((item) => item.isCurrentPage)?.value ?? props.items[0]?.value ?? ''
)
</script>
