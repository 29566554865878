<template>
  <AccordionHeader
    class="flex justify-between [&+.accordion-content]:hover:bg-slate-100 [&+.accordion-content]:active:bg-slate-100">
    <AccordionTrigger
      v-bind="props"
      :class="
        cn(
          'group flex flex-1 items-center justify-between gap-x-2 px-4 py-2 text-sm font-medium text-slate-900 transition-all hover:bg-slate-200 active:bg-slate-300 disabled:pointer-events-none disabled:text-slate-400',
          $attrs.class ?? ''
        )
      ">
      <Icon v-if="prefixIcon" :name="prefixIcon" />
      <div :class="cn('flex flex-1 items-center justify-between gap-x-2', props.class)">
        <slot />
      </div>
      <div class="trigger-icon">
        <icon :name="suffixIcon" :class="cn('transition-all duration-300 ease-in-out', open && 'rotate-90')" />
      </div>
    </AccordionTrigger>
  </AccordionHeader>
</template>

<script setup lang="ts">
import { AccordionHeader, AccordionTrigger } from 'radix-vue'
import { cn } from '@/lib/utils'
import type { AccordionTriggerProps } from '~/components/ui/accordion/types'

const props = withDefaults(defineProps<AccordionTriggerProps>(), {
  suffixIcon: 'heroicons:chevron-right'
})
</script>
