interface RouteLocation {
  name?: string | symbol | null
  path: string
  matched: Array<{ path: string }>
}

/**
 * 경로에서 "/:tenant?" 부분을 제거하는 경로 정리 컴포저블
 * 멀티테넌트 환경에서 경로를 정리하여 일관된 라우트 경로 관리를 지원합니다.
 * @param providedRoute - 직접 전달할 라우트 객체
 * @returns {Object} cleanPath, cleanMatchedPath - 테넌트 부분이 제거된 정리된 경로
 */
export const useCleanRoutePath = (providedRoute?: RouteLocation): { cleanPath: string; cleanMatchedPath: string } => {
  const route = providedRoute || useRoute()

  if (!route || !route.path) {
    return { cleanPath: '/', cleanMatchedPath: '/' }
  }

  const cleanPath = route.name?.toString().includes(':tenant?') ? route.path.replace('/:tenant?', '') : route.path

  const cleanMatchedPath = route.matched?.length > 0 ? route.matched[0].path.replace('/:tenant?', '') : cleanPath

  return { cleanPath, cleanMatchedPath }
}
