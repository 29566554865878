<template>
  <div
    :class="
      cn(
        'group sticky left-0 top-0 z-50',
        'h-full max-h-screen w-[20rem] xl:w-[22.5rem]',
        'border-x border-slate-300 bg-white-950',
        isTransitionEnabled && 'transition-transform !duration-300 xl:transition-[width,transform]',
        isOpen
          ? `translate-x-0 max-xl:[box-shadow:0px_16px_32px_0px_#1D212D1A,0px_1px_4px_0px_#1D212D26,0px_0px_1px_0px_#1D212D33] ${ASIDE_STYLES.default}`
          : '-translate-x-[19.0625rem] pl-4 xl:w-0 xl:translate-x-0'
      )
    ">
    <div :class="cn('h-full w-full overflow-hidden', !isOpen && '-translate-x-4')">
      <slot />
    </div>
    <div
      :class="
        cn(
          'absolute right-0 top-0 z-10 h-full w-8 translate-x-1/2 cursor-pointer',
          isHover &&
            'before:absolute before:right-1/2 before:top-0 before:h-full before:w-[2px] before:translate-x-1/2 before:bg-blue-600'
        )
      "
      v-on="{
        mouseenter: handleHover,
        mouseleave: handleHover
      }">
      <Button
        v-if="!isXL || isHover"
        :class="cn('absolute right-1/2 top-5 translate-x-1/2', !isHover && 'bg-white-950')"
        :variant="isHover ? 'solid' : 'outline'"
        :color="isHover ? 'blue' : 'slate'"
        size="sm"
        :icon="buttonIcon"
        @click="toggleSidebar" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from '@vueuse/core'
import { breakpointsTailwind } from '~/constants/shared/breakpointsTailwind'
import { ASIDE_STYLES } from '~/constants/shared/styles'
import { cn } from '~/lib/utils'

const breakpoints = useBreakpoints(breakpointsTailwind)
const isLargerThanXL = breakpoints.greaterOrEqual('xl')

const isOpen = defineModel<boolean>({ default: false })
const isHover = ref(false)

const isTransitionEnabled = ref(false)

watch(isLargerThanXL, (value) => {
  isTransitionEnabled.value = false
  if (!value) {
    if (isOpen.value) {
      isTransitionEnabled.value = true
      isOpen.value = false
    }
  }
  if (value) {
    if (!isOpen.value) {
      isTransitionEnabled.value = true
      isOpen.value = true
    }
  }
  setTimeout(() => {
    isTransitionEnabled.value = true
  }, 300)
})

const toggleSidebar = () => {
  isOpen.value = !isOpen.value
}

const handleHover = (event: MouseEvent) => {
  isHover.value = event.type === 'mouseenter'
}

const isXL = computed(() => breakpoints.current().value.includes('xl'))

const buttonIcon = computed(() => {
  if (!isXL.value) {
    return isHover.value ? (isOpen.value ? 'heroicons:chevron-left' : 'heroicons:chevron-right') : 'heroicons:bars-3'
  }
  return isOpen.value ? 'heroicons:chevron-left' : 'heroicons:chevron-right'
})

onMounted(() => {
  nextTick(() => {
    isTransitionEnabled.value = true
  })
})
</script>
