<template>
  <AccordionRoot v-bind="delegatedProps" v-model="model" class="group">
    <AccordionItem
      v-for="(item, index) in items"
      :key="item?.value ?? `item-${index}`"
      :value="item?.value ?? `item-${index}`"
      :disabled="item?.disabled">
      <template #default="{ open }">
        <slot name="trigger" :item :index>
          <AccordionTrigger v-if="item?.trigger" v-bind="item?.trigger" :open>
            <slot :name="item.slot ? `${item.slot}-item` : 'item'" :item :index>
              {{ item?.trigger?.label }}
            </slot>
          </AccordionTrigger>
        </slot>
        <AccordionContent>
          <slot :name="item.slot || 'content'" :item :index>
            {{ item?.content }}
          </slot>
        </AccordionContent>
      </template>
    </AccordionItem>
  </AccordionRoot>
</template>

<script setup lang="ts">
import type { AccordionProps } from '~/components/ui/accordion/types'

const props = withDefaults(defineProps<AccordionProps>(), {
  type: 'single',
  collapsible: true
})

const delegatedProps = computed(() => {
  const { items: _, ...delegated } = props

  return delegated
})

const model = defineModel<string>({
  default: ''
})
</script>
