import { cva } from 'class-variance-authority'

export { default as DirectoryNavigation } from './DirectoryNavigation.vue'
export { default as DirectoryNavigationAccordion } from './DirectoryNavigationAccordion.vue'
export { default as DirectoryNavigationHeader } from './DirectoryNavigationHeader.vue'
export { default as DirectoryNavigationHeadline } from './DirectoryNavigationHeadline.vue'
export { default as DirectoryNavigationSummary } from './DirectoryNavigationSummary.vue'
export { default as DirectoryNavigationLink } from './DirectoryNavigationLink.vue'

export const directoryNavigationLinkVariants = cva('flex w-full gap-x-2 bg-transparent py-3 text-base font-medium', {
  variants: {
    variant: {
      default:
        'text-slate-600 hover:bg-slate-200 focus:bg-blue-100 focus:text-blue-700 active:bg-blue-100 active:text-blue-700 [&>svg]:text-slate-600',
      destructive:
        'text-red-600 hover:bg-red-200 focus:bg-red-300 focus:text-red-700 active:bg-red-300 active:text-red-700 [&>svg]:text-red-500'
    },
    isCurrentPage: {
      true: '',
      false: ''
    },
    prefixIcon: {
      true: 'px-4',
      false: 'pl-12 pr-4'
    }
  },
  compoundVariants: [
    { variant: 'default', isCurrentPage: true, class: '![&>svg]:text-blue-600 !bg-blue-100 !text-blue-700' },
    { variant: 'destructive', isCurrentPage: true, class: '![&>svg]:text-red-600 !bg-red-300 !text-red-700' }
  ]
})

export const directoryNavigationLinkIconVariants = cva('h-6 w-6', {
  variants: {
    variant: {
      default: 'text-slate-600 active:text-blue-600',
      destructive: 'text-red-500 active:text-red-600'
    },
    isCurrentPage: { true: '', false: '' }
  },
  compoundVariants: [
    { variant: 'default', isCurrentPage: true, class: '!text-blue-600' },
    { variant: 'destructive', isCurrentPage: true, class: '!text-red-600' }
  ]
})
