import type { RouteParamsGeneric } from 'vue-router'
import type { PageMetaQueryResult } from '~/composables/shared/usePageMetaQuery'

interface Temp {
  menu: ComputedRef<DirectoryRoutesPageNode[]>
  getSlugMappedNodes: (
    nodes: Array<DirectoryRoutesPageNode | PageNode>,
    slugMap: PageMetaQueryResult['breadcrumbs'] | RouteParamsGeneric
  ) => DirectoryRoutesPageNode[]
  getMenuByRouteName: (routeName: string, mode: 'route-name' | 'directory-path') => DirectoryRoutesPageNode[]
  getPathMappedMenuByRouteName: ({
    routeName,
    mode,
    slugMap
  }: {
    routeName: string
    mode: 'route-name' | 'directory-path'
    slugMap?: PageMetaQueryResult['breadCrumbs'] | RouteParamsGeneric
  }) => DirectoryRoutesPageNode[]
}

export const useDirectoryRoutes = () => {
  return useNuxtApp().$directoryRoutes as Temp
}
