<template>
  <div class="relative w-full max-w-[100vw]">
    <!-- Header -->
    <slot name="header">
      <Header v-if="header" :variant="route.meta.variant as ApplicationVariant" />
    </slot>
    <ResponsiveContainer class="flex">
      <aside v-if="asideLeft" class="absolute h-full w-fit xl:relative xl:h-[inherit]">
        <slot name="aside-left">
          <Sidebar v-if="sidebar" v-model="sidebarOpen">
            <DirectoryNavigation />
          </Sidebar>
        </slot>
      </aside>
      <div
        :class="
          cn(
            'relative flex w-screen min-w-[80rem] flex-col items-center xl:w-full',
            'transition-all duration-300',
            !sidebar && 'border-l border-slate-300',
            asideLeft && 'border-r border-slate-300',
            footer ? 'min-h-[calc(100vh-8rem)]' : 'min-h-[calc(100vh-4rem)]'
          )
        ">
        <nav
          v-if="nav"
          :class="
            cn(
              'content-header sticky top-0 z-40 w-full bg-white-950 transition-all duration-300 ease-in-out',
              breadcrumb && 'border-b border-slate-300'
            )
          ">
          <slot name="nav">
            <div class="flex min-h-16 w-full items-center justify-between px-10 py-3">
              <Breadcrumb v-if="breadcrumb" class="w-2/3" />
              <slot name="nav-button" />
            </div>
          </slot>
          <AlertBanner class="alert-banner w-full" />
        </nav>
        <main :class="cn('flex h-full w-full first:[&>*]:py-10')">
          <div class="flex h-full w-full flex-col items-center px-10">
            <slot />
          </div>
          <aside
            v-if="$slots['aside-right']"
            :class="[
              cn(
                'sticky h-full max-h-[calc(100vh-4rem)] min-h-[calc(100vh-4rem)] border-l border-slate-300',
                alertBanner.state.value.active ? 'top-28' : 'top-16'
              ),
              !isAsideRightOpen && 'border-l-0'
            ]">
            <slot name="aside-right" />
          </aside>
        </main>
      </div>
    </ResponsiveContainer>
    <slot name="footer">
      <Footer v-if="footer" />
    </slot>
  </div>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import ResponsiveContainer from '~/components/shared/responsive-container/ResponsiveContainer.vue'
import AlertBanner from '~/components/ui/alert/AlertBanner.vue'
import { useAlertBanner } from '~/composables/shared/useAlertBanner'
import { cn } from '~/lib/utils'

withDefaults(
  defineProps<{
    header?: boolean
    asideLeft?: boolean
    sidebar?: boolean
    nav?: boolean
    breadcrumb?: boolean
    footer?: boolean
    main?: {
      class: HTMLAttributes['class']
    }
    isAsideRightOpen?: boolean
  }>(),
  {
    header: true,
    asideLeft: true,
    sidebar: true,
    nav: true,
    breadcrumb: true,
    footer: false
  }
)

const route = useRoute()
const alertBanner = useAlertBanner()
useI18n({ useScope: 'local' })

const sidebarOpen = useState('sidebarOpen', () => true)
</script>

<i18n>
{
  "ko": {
    "projects": {
      "index": "프로젝트",
      "collection": {
        "index": "컬렉션",
        "tasks": "작업",
        "assignments": "할당작업",
        "exports": "익스포트 현황",
        "GT": "GT 데이터셋"
      },
      "workforce": {
        "index": "워크포스",
        "workers": {
          "index": "워커"
        },
        "workshops": {
          "index": "워크샵",
          "assignments": {
            "index": "할당작업",
            "individual": "개별할당작업",
            "requirements": "할당 필요 작업"
          },
          "worker": {
            "index": "@:projects.workforce.workers.index"
          },
          "data-lab": {
            "index": "@:projects.workforce.workers.index"
          }
        },
        "QC": {
          "index": "검수 태그"
        },
        "guides": {
          "index": "게시판",
          "create": "게시글 작성",
          "modify": "게시글 수정"
        }
      },
      "settings": {
        "index": "설정",
        "default": "기본정보",
        "collection": "컬렉션",
        "annotation": "어노테이션",
        "workflow": "작업 설정",
        "members": "멤버",
        "tags": "태그",
        "danger": "Danger Zone"
      },
      "statistics": {
        "index": "통계",
        "worker": { 
          "index": "워커 통계" ,
          "assignment": "할당작업 통계",
          "annotation": "어노테이션 통계", 
          "action": "액션 통계"
        },
        "discard": {
          "index": "불량 통계"
        },
        "classification": {
          "index": "클래스 통계" 
        },
        "reject": { 
          "index": "반려 통계",
          "worker": "작업자 반려 통계",
          "QC": "검수태그 반려 통계"  
        }
      }
    },
    "account": {
      "index": "계정",
      "profile": {
        "index": "회원 정보"
      },
      "manage": {
        "index": "계정 설정"
      },
      "verification": {
        "index": "인증"
      }
    },
    "settings": {
      "index": "설정",
      "personal": {
        "index": "개인 설정"
      },
      "members": {
        "index": "멤버 관리"
      }
    },
    "infra": {
      "index": "인프라",
      "agents": {
        "index": "에이전트"
      }
    },
    "plugins": {
      "index": "플러그인",
      "neural_net": {
        "index": "인공신경망"
      }
    },
    "catalog": {
      "index": "카탈로그",
      "collections": {
        "index": "컬렉션",
        "data-units": {
          "index": "@:catalog.data-units.index"
        },
        "settings": {
          "index": "@:catalog.settings.index",
          "default": "@:catalog.settings.default",
          "schema": "@:catalog.settings.schema",
          "members": "@:catalog.settings.members",
          "danger": "@:catalog.settings.danger"
        },
        "imports": {
          "index": "@:catalog.imports.index"
        }
      },
      "data-units": {
        "index": "데이터 유닛"
      },
      "imports": {
        "index": "업로드 현황"
      },
      "settings": {
        "index": "컬렉션 설정",
        "default": "기본정보",
        "schema": "데이터 구조",
        "members": "멤버",
        "danger": "Danger Zone"
      }
    },
    "data-lab": {
      "index": "데이터 랩",
      "assignments": {
        "index": "할당작업",
        "discarded-tasks": "불량 접수 목록",
        "tasks": "할당작업 목록"
      },
      "statistics": {
        "index": "통계"
      },
      "guide-line": {
        "index": "안내게시판"
      },
      "workshop": {
        "index": "워크샵",
        "assignments": {
          "index": "@:data-lab.assignments.index",
          "tasks": "@:data-lab.assignments.tasks",
          "discarded-tasks": "@:data-lab.assignments.discarded-tasks"
        },
        "guide-line": {
          "index": "@:data-lab.guide-line.index"
        },
        "statistics": {
          "index": "@:data-lab.statistics.index",
          "task": {
            "index": "작업 통계",
            "assignment": "할당작업 통계",
            "annotation": "어노테이션 통계",
            "action": "액션 통계"
          }
        }
      }
    },
    "ml": {
      "index": "머신러닝",
      "trainings": {
        "index": "학습"
      },
      "experiments": {
        "index": "실험",
        "learnings": {
          "index": "학습"
        }
      },
      "models": {
        "index": "모델"
      }
    }
  },
  "en": {
    "projects": {
      "index": "Projects",
      "collection": {
        "index": "Collection",
        "tasks": "Tasks",
        "assignments": "Assigned Tasks",
        "exports": "Export Status",
        "GT": "Gt Dataset"
      },
      "workforce": {
        "index": "Workforce",
        "workers": {
          "index": "Workers"
        },
        "workshops": {
          "index": "Workshops",
          "worker": {
            "index": "@:projects.workforce.workers.index"
          },
          "assignments": {
            "index": "Assigned Tasks",
            "individual": "Individual Assigned Tasks",
            "requirements": "Required Assignments"
          },
          "data-lab": {
            "index": "@:projects.workforce.workers.index"
          }
        },
        "QC": {
          "index": "Quality Control"
        },
        "guides": {
          "index": "Guideline Board",
          "create": "Create Post",
          "modify": "Modify Post"
        }
      },
      "settings": {
        "index": "Settings",
        "default": "Basic Information",
        "collection": "Collection",
        "annotation": "Annotation",
        "workflow": "Task Settings",
        "members": "Members",
        "tags": "Tags",
        "danger": "Danger Zone"
      }, 
      "statistics": {
        "index": "Statistics",
        "worker": {
          "index": "Worker Statistics"
        },
        "discard": {
          "index": "Discarded Statistics"
        },
        "classification": {
          "index": "Classification Statistics"
        },
        "reject": {
          "index": "Rejection Statistics",
          "worker": "Worker Rejection Statistics",
          "QC": "QC Rejection Statistics"
        }
      }
    },
    "account": {
      "index": "Account",
      "profile": {
        "index": "Profile Information"
      },
      "manage": {
        "index": "Account Settings"
      },
      "verification": {
        "index": "Verification"
      }
    },
    "settings": {
      "index": "Settings",
      "personal": {
        "index": "Personal Settings"
      },
      "members": {
        "index": "Member Management"
      }
    },
    "infra": {
      "index": "Infrastructure",
      "agents": {
        "index": "Agent"
      }
    },
    "plugins": {
      "index": "Plugin",
      "neural_net": {
        "index": "Neural Net"
      }
    },
    "catalog": {
      "index": "Catalog",
      "collections": {
        "index": "Collections",
        "data-units": {
          "index": "@:catalog.data-units.index"
        },
        "settings": {
          "index": "@:catalog.settings.index",
          "default": "@:catalog.settings.default",
          "schema": "@:catalog.settings.schema",
          "members": "@:catalog.settings.members",
          "danger": "@:catalog.settings.danger"
        },
        "imports": {
          "index": "@:catalog.imports.index"
        }
      },
      "data-units": {
        "index": "Data Units"
      },
      "imports": {
        "index": "Upload Status"
      },
      "settings": {
        "index": "Collection Settings",
        "default": "Basic Information",
        "schema": "Data Schema",
        "members": "Members",
        "danger": "Danger Zone"
      }
    },
    "data-lab": {
      "index": "Data Lab",
      "assignments": {
        "index": "Assigned Tasks",
        "discarded-tasks": "Discarded Task List",
        "tasks": "Assigned Task List"
      },
      "statistics": {
        "index": "Statistics"
      },
      "guide-line": {
        "index": "Guideline Board"
      },
      "workshop": {
        "index": "Workshop",
        "assignments": {
          "index": "@:data-lab.assignments.index",
          "tasks": "@:data-lab.assignments.tasks",
          "discarded-tasks": "@:data-lab.assignments.discarded-tasks"
        },
        "guide-line": {
          "index": "@:data-lab.guide-line.index"
        },
        "statistics": {
          "index": "@:data-lab.statistics.index",
          "task": {
            "index": "Task Statistics",
            "assignment": "Assigned Task Statistics",
            "annotation": "Annotation Statistics",
            "action": "Action Statistics"
          }
        }
      }
    }
  },
  "ml": {
    "index": "Machine Learning",
    "trainings": {
      "index": "Trainings"
    },
    "experiments": {
      "index": "Experiments"
    },
    "models": {
      "index": "Models"
    }
  }
}
</i18n>
