<template>
  <div class="relative grid h-full w-full grid-rows-[min-content_1fr_min-content]">
    <DirectoryNavigationHeader>
      <template v-if="queryData && Object.keys(queryData).length">
        <DirectoryNavigationHeadline v-if="queryData?.headline" :favorite="queryData?.headline?.favorite">
          <template #text>
            {{ queryData?.headline?.value }}
          </template>
        </DirectoryNavigationHeadline>
        <DirectoryNavigationSummary>
          <template #title>
            {{ $t(queryData.title) }}
          </template>
          <template v-if="queryData?.description" #description>
            {{ queryData?.description }}
          </template>
          <template v-if="queryData?.created" #created>
            {{ queryData?.created }}
          </template>
        </DirectoryNavigationSummary>
        <Media v-if="queryData?.media" :title="queryData?.media.title">
          <template #thumbnail>
            <Avatar type="image" :value="queryData?.media.thumbnail ?? ''" size="sm" rounded />
          </template>
        </Media>
        <div v-if="query.data?.badgeGroups?.length" class="flex flex-col gap-1.5">
          <BadgeGroup
            v-for="(badgeGroup, index) in queryData.badgeGroups"
            :key="`badge-group-${index}`"
            v-bind="badgeGroup"
            :max-length="badgeGroup.maxLength ?? 20" />
        </div>
        <Button
          v-if="queryData?.link"
          v-bind="queryData?.link"
          as="NuxtLink"
          suffix-icon="heroicons:chevron-right"
          variant="outline"
          class="w-full max-w-[23.875rem] truncate [&>div]:w-full [&>div]:justify-between"
          color="slate" />
        <FormKit
          v-if="queryData?.select"
          type="select"
          v-bind="queryData?.select"
          ignore
          @input="(value) => navigateTo(`${queryData?.select?.toPrefix}/${value}`)" />
      </template>
      <template v-else>
        <DirectoryNavigationSummary>
          <template #title>
            {{ route.path.split('/')[route.path.split('/').length - 1] }}
          </template>
        </DirectoryNavigationSummary>
      </template>
    </DirectoryNavigationHeader>
    <div class="h-full overflow-y-auto border-t border-slate-300">
      <DirectoryNavigationAccordion
        v-if="directoryNavigationItems"
        :items="convertAccordionItems(directoryNavigationItems)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { useCleanRoutePath } from '~/composables/shared/useCleanRoutePath'
import { useDirectoryRoutes } from '~/composables/shared/useDirectoryRoutes'
import type { PageMetaMenuItem, PageMetaQueryResult } from '~/composables/shared/usePageMetaQuery'
import { MENU_DEPTH } from '~/constants/shared/menuDepth'
import { PAGE_META } from '~/constants/shared/query'
import { cn } from '~/lib/utils'
import { objectToArrayByExcludedKeys, objectToArrayByIncludedKeys } from '~/utils/object'
import type { DirectoryNavigationAccordionItems, DirectoryNavigationLinkItem, DirectoryNavigationProps } from './types'

defineProps<DirectoryNavigationProps>()
const route = useRoute()
const { cleanMatchedPath } = useCleanRoutePath()
const { t } = useI18n({ useScope: 'parent' })
const { t: translateAtLocalScope } = useI18n({ useScope: 'local' })
const { getPathMappedMenuByRouteName } = useDirectoryRoutes()

const query = await useQuery<PageMetaQueryResult>({
  queryKey: [PAGE_META.primaryKey, route.path, route.params]
}).suspense()

const queryData = computed(() => query.data)

const convertChildToAccordionItems = (
  childs: {
    [p: string]: DirectoryRoutesPageNode
  },
  pageMetaChildren: PageMetaMenuItem['children'] = {},
  { mainCategory, parent }: { mainCategory: string; parent: string }
): DirectoryNavigationLinkItem[] => {
  const itemMap: Record<string, DirectoryNavigationLinkItem> = {}

  if (!Object.keys(pageMetaChildren).length) return []

  for (const key in childs) {
    const node = childs[key]
    const nodePathNames = node.name.split('__')
    const label = nodePathNames[nodePathNames.length - 1]

    let translatedLabel = t(`${mainCategory}.${parent}.${label}`)
    const splitBreadcrumb = translatedLabel.split('.')
    if (splitBreadcrumb.length > 1) {
      translatedLabel = translateAtLocalScope(translatedLabel)
    }

    itemMap[label] = {
      ...pageMetaChildren?.[label],
      to: pageMetaChildren?.[label]?.to ?? node.path,
      label: pageMetaChildren?.[label]?.label ?? translatedLabel,
      isCurrentPage: route.path.includes(node.path)
    }
  }
  const customOrderedKeys = Object.keys(pageMetaChildren || {}) || []
  const orderedItems = objectToArrayByIncludedKeys(itemMap, customOrderedKeys)
  const restItems = objectToArrayByExcludedKeys(itemMap, customOrderedKeys)

  return restItems.concat(orderedItems)
}

const createMenuItem = (node: DirectoryRoutesPageNode, label: string) => {
  const isHasChildren = !Object.keys(node.children).length
  const pageMetaData = queryData.value?.directoryNavigation?.[label]
  const mainCategory = getRouteNameByFullPath(node.path, 1)

  const translatedLabel = label ? t(`${mainCategory}.${label}.index`) : t(`${mainCategory}.index`)

  return {
    trigger: {
      ...pageMetaData,
      class: cn(
        '!text-base !font-medium text-slate-600 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-slate-500 [&_.trigger-icon>svg]:h-5 [&_.trigger-icon>svg]:w-5',
        pageMetaData?.class,
        isHasChildren ? '' : '!py-1.5'
      ),
      to: pageMetaData?.to || (isHasChildren ? node.path : ''),
      label: pageMetaData?.label || translatedLabel,
      prefixIcon:
        pageMetaData?.prefixIcon || (isHasChildren ? 'heroicons:arrow-top-right-on-square' : 'heroicons:folder'),
      isCurrentPage: pageMetaData?.to ? route.path.includes(pageMetaData?.to as string) : false
    },
    children: isHasChildren
      ? []
      : convertChildToAccordionItems(node.children, pageMetaData?.children || {}, { mainCategory, parent: label }),
    isCurrentPage: route.path.includes(node.path),
    value: label
  }
}

const convertAccordionItems = (pageNode: DirectoryRoutesPageNode): DirectoryNavigationAccordionItems[] => {
  const itemMap: Record<string, DirectoryNavigationAccordionItems> = {}

  const pageNodePathNames = pageNode.name.split('__')
  const pageNodeLabel = pageNodePathNames[pageNodePathNames.length - 1]

  itemMap[pageNodeLabel] = createMenuItem(pageNode, pageNodeLabel)

  pageNode.siblings.forEach((sibling) => {
    const siblingPathNames = sibling.name.split('__')
    const siblingLabel = siblingPathNames[siblingPathNames.length - 1]

    itemMap[siblingLabel] = createMenuItem(sibling, siblingLabel)
  })

  const customOrderedKeys = Object.keys(queryData.value?.directoryNavigation || {}) || []

  const orderedItems = objectToArrayByIncludedKeys(itemMap, customOrderedKeys)
  const restItems = objectToArrayByExcludedKeys(itemMap, customOrderedKeys)

  return restItems.concat(orderedItems).filter(Boolean)
}

const directoryNavigationItems = computed(() => {
  const featurePathName = cleanMatchedPath.split('/').filter(Boolean)[0]

  return getPathMappedMenuByRouteName({
    routeName: cleanMatchedPath,
    mode: 'directory-path'
  })[MENU_DEPTH[featurePathName as keyof typeof MENU_DEPTH] || 0]
})
</script>

<i18n>
{
  "ko": {
    "projects": {
      "workforce": {
        "workshops": "워크샵",
        "QC": "검수태그",
        "guides": "게시판",
        "workers": "워커"
      }, 
      "statistics": {
        "worker": "워커 통계",
        "discard": "불량 통계",
        "classification": "클래스 통계",
        "reject": "반려 통계"
      }
    },
    "infra": {
      "agents": "에이전트 관리"
    },
    "plugins": {
      "neural_net": "인공신경망"
    },
    "data-lab": {
      "statistics": {
        "task": "작업 통계"
      }
    }
  },
  "en": {
    "projects": {
      "workforce": {
        "workshops": "Workshops", 
        "QC": "QC",
        "guides": "Guides",
        "workers": "Workers"
      },
      "statistics": {
        "worker": "Worker Statistics",
        "discard": "Discarded Statistics",
        "classification": "Classification Statistics",
        "reject": "Rejection Statistics"
      }
    },
    "infra": {
      "agents": "Agents Management"
    },
    "data-lab": {
      "statistics": {
        "task": "Task Statistics"
      }
    },
    "plugins": {
      "neural_net": "Neural Network"
    }
  }
}
</i18n>
