<template>
  <div class="flex flex-col gap-1">
    <div class="flex flex-col gap-2">
      <h1 class="line-clamp-1 text-base font-semibold leading-6 text-slate-900">
        <slot name="title" />
      </h1>
      <p class="line-clamp-3 text-sm font-normal leading-5 text-slate-600">
        <slot name="description" />
      </p>
      <p v-if="instance?.slots['created']" class="mt-1 line-clamp-3 text-sm font-normal leading-5 text-slate-600">
        <slot name="created" />
      </p>
    </div>

    <Dialog v-if="instance && instance.slots['title'] && instance.slots['description']" v-model:open="isMoreOpen">
      <Button
        variant="ghost"
        suffix-icon="heroicons:chevron-right"
        class="relative -left-1 !py-0.5 !pl-1 !pr-0"
        color="blue">
        더보기
      </Button>
      <template #header-title>
        <h1 class="line-clamp-2 w-full text-base font-semibold leading-6 text-slate-900">
          <slot name="title" />
        </h1>
        <Button icon="heroicons:x-mark" variant="ghost" color="slate" size="lg" @click="isMoreOpen = !isMoreOpen" />
      </template>
      <template #body-inner>
        <p class="text-sm font-normal leading-5 text-slate-600">
          <slot name="description" />
        </p>
      </template>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
const instance = getCurrentInstance()
const isMoreOpen = ref(false)
</script>
