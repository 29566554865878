export const MENU_DEPTH = {
  account: 1,
  projects: 2,
  catalog: 3,
  settings: 1,
  'data-lab': 5,
  ml: 3,
  plugins: 3,
  infra: 2
}
