<template>
  <NuxtLink
    v-bind="props"
    :to
    :class="directoryNavigationLinkVariants({ variant, prefixIcon: !!prefixIcon, isCurrentPage })">
    <Icon
      v-if="prefixIcon"
      :name="prefixIcon"
      :class="directoryNavigationLinkIconVariants({ variant, isCurrentPage })" />
    <slot>
      <span>{{ label }}</span>
    </slot>
  </NuxtLink>
</template>

<script setup lang="ts">
import { directoryNavigationLinkVariants, directoryNavigationLinkIconVariants } from '.'
import type { DirectoryNavigationLinkProps } from './types'

const props = withDefaults(defineProps<DirectoryNavigationLinkProps>(), {
  to: '/',
  variant: 'default',
  label: ''
})
</script>
