<template>
  <div aria-label="breadcrumb">
    <div class="flex flex-wrap items-center">
      <BreadcrumbItem v-for="(node, index) in displayItems" :key="node.name">
        <BreadcrumbPage v-if="index === displayItems.length - 1">
          {{
            slugIndexes.includes(index)
              ? parseSlugName(node)
              : translate(node.name, { isLastNode: true, separator: '__' })
          }}
        </BreadcrumbPage>
        <BreadcrumbPage v-else class="text-slate-600">
          {{ slugIndexes.includes(index) ? $t(getLastNodeName(node.name)) : translate(node.name) }}
        </BreadcrumbPage>
        <!-- 
        TODO: 추후 개발을 위한 Backup Code
        <Button
          v-else
          class="p-0 hover:underline hover:underline-offset-2"
          variant="link"
          color="slate"
          as="NuxtLink"
          :to="node.path">
          {{ slugIndexes.includes(index) ? $t(getLastNodeName(node.name)) : translate(node.name) }}
        </Button> 
        -->
        <DropdownMenu
          v-if="node.siblings.length > 1 && index > 0"
          :items="[convertSiblingToDropdownMenuItems(node, index)]"
          class="dropdown-menu">
          <Button variant="ghost" color="slate" size="xs" icon="heroicons:chevron-down" squared class="ml-1" />
        </DropdownMenu>
        <BreadcrumbSeparator v-if="index !== displayItems.length - 1" class="w-6">
          <span class="text-sm font-normal text-slate-400">/</span>
        </BreadcrumbSeparator>
      </BreadcrumbItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { cloneDeep, has, isEmpty } from 'lodash-es'
import type { DropdownMenuItems } from '~/components/ui/dropdown-menu/types'
import { useCleanRoutePath } from '~/composables/shared/useCleanRoutePath'
import { useDirectoryRoutes } from '~/composables/shared/useDirectoryRoutes'
import type { PageMetaQueryResult } from '~/composables/shared/usePageMetaQuery'
import { PAGE_META } from '~/constants/shared/query'
import { getLastNodeName } from '~/utils/routes'
import type { BreadcrumbProps } from './types'

const props = defineProps<BreadcrumbProps>()
const { t } = useI18n({ useScope: 'parent' })
const { t: $t } = useI18n({ useScope: 'global' })
const { t: translateAtLocalScope } = useI18n({ useScope: 'local' })
const route = useRoute()
const { cleanMatchedPath } = useCleanRoutePath()
const { menu, getPathMappedMenuByRouteName } = useDirectoryRoutes()

const query = await useQuery<PageMetaQueryResult>({
  queryKey: [PAGE_META.primaryKey, route.path, route.params]
}).suspense()

const slugIndexes = computed<number[]>(() =>
  cleanMatchedPath
    .split('/')
    .map((name, index) => (name.includes(':') ? index - 1 : -1))
    .filter((index) => index !== -1)
)

const convertSiblingToDropdownMenuItems = (pageNode: DirectoryRoutesPageNode, index: number) => {
  const itemMap: Record<string, DropdownMenuItems> = {}
  let customOrderMap = cloneDeep(query.data?.directoryNavigation) || {}

  // 현재 페이지 포함
  const currentPathNames = pageNode.name.split('__').filter(Boolean)
  const currentLabel = currentPathNames[currentPathNames.length - 1]

  itemMap[currentLabel] = {
    label: translate(pageNode.name, { isLastNode: index === displayItems.value?.length - 1, separator: '__' }),
    to: pageNode.path,
    disabled: true
  }
  // 형제 페이지 포함
  pageNode.siblings.forEach((sibling) => {
    const siblingPathNames = sibling.name.split('__').filter(Boolean)
    const siblingLabel = siblingPathNames[siblingPathNames.length - 1]

    if (siblingPathNames.length > 1 && sibling.path !== pageNode.path) {
      itemMap[siblingLabel] = {
        label: translate(sibling.name, { isLastNode: index === displayItems.value?.length - 1, separator: '__' }),
        to: sibling.path
      }
    }
  })

  const paths = pageNode.path.split('/')
  const currentPath = paths[paths.length - 1]

  if (!has(query.data?.directoryNavigation, currentPath)) {
    const temp = paths.find((path) => has(query.data?.directoryNavigation, path))

    customOrderMap = query.data?.directoryNavigation?.[temp || '']?.children || {}
  }

  const customOrderedKeys = Object.keys(customOrderMap) || []
  const orderedItems = objectToArrayByIncludedKeys(itemMap, customOrderedKeys).filter(Boolean)
  const restItems = objectToArrayByExcludedKeys(itemMap, customOrderedKeys).filter(Boolean)

  return restItems.concat(orderedItems)
}

const displayItems = computed(() => {
  if (!isEmpty(props.items)) return props.items || []

  return query.data?.breadCrumbs
    ? getPathMappedMenuByRouteName({
        routeName: cleanMatchedPath,
        mode: 'directory-path',
        slugMap: query.data.breadCrumbs
      })
    : menu.value
})

function parseSlugName(node: DirectoryRoutesPageNode) {
  const name = getLastNodeName(node.name)

  // route에서 slug를 나타내는 형식을 바탕으로 검증
  if (name.startsWith(':') && name.endsWith('()')) {
    const lastName = getLastNodeName(node.path, '/')
    const parsedCode = excludeSlugCode(node.path, '/')

    return t(`${parsedCode}.${lastName}`)
  } else return $t(getLastNodeName(node.name))
}

function excludeSlugCode(code: string, separator: string = '__') {
  const splitCodes = code.split(separator)
  if (separator === '/') splitCodes.shift()
  return splitCodes.filter((_, index) => !slugIndexes.value.includes(index)).join('.')
}

function translate(
  code: string,
  options: { isLastNode?: boolean; separator: string } = { isLastNode: false, separator: '__' }
) {
  let breadcrumb: string
  if (options.isLastNode) {
    breadcrumb = t(excludeSlugCode(code, options.separator))
  } else {
    breadcrumb = t(`${excludeSlugCode(code)}.index`)
  }

  const splitBreadcrumb = breadcrumb.split('.')
  if (splitBreadcrumb.length > 1) {
    breadcrumb = translateAtLocalScope(breadcrumb)
  }

  return breadcrumb
}
</script>

<i18n>
{
  "ko": {
    "account": {
      "profile": "회원 정보",
      "manage": "계정 설정",
      "verification": "인증",
      "deactivate": "회원 탈퇴"
    },
    "projects": {
      "workforce": {
        "workshops": "워크샵",
        "QC": "검수태그",
        "guides": "게시판",
        "workers": "워커"
      },
      "statistics": {
        "worker": "워커 통계",
        "discard": "불량 통계",
        "classification": "클래스 통계",
        "reject": "반려 통계" 
      }
    },
    "catalog": {
      "collections": {
        "data-units": "데이터 유닛",
        "imports": "업로드 현황",
        "settings": "설정"
      }
    },
    "settings": {
      "personal": "개인 설정",
      "members": "멤버 관리",
    },
    "infra": {
      "agents": "에이전트 관리"
    },
    "plugins": {
      "neural_net": "인공신경망"
    },
    "ml": {
      "experiments": {
        "learnings": "학습",
      }
    },
    "data-lab": {
      "workshop": {
        "guide-line": "안내게시판",
        "statistics": "통계",
        "assignments": "할당작업"
      }
    }
  },
  "en": {
    "account": {
      "profile": "Profile",
      "manage": "Account Settings",
      "verification": "Verification",
      "deactivate": "Deactivate Account"
    },
    "projects": {
      "workforce": {
        "workshops": "Workshops",
        "QC": "QC",
        "guides": "Guides",
        "workers": "Workers"
      }, 
      "statistics": {
        "worker": "Worker Statistics",
        "discard": "Discarded Statistics",
        "classification": "Classification Statistics",
        "reject": "Rejection Statistics"
      }
    },
    "catalog": {
      "collections": {
        "data-units": "Data Units",
        "imports": "Upload Status",
        "settings": "Settings"
      }
    },
    "settings": {
      "personal": "Personal Settings",
      "members": "Member Management",
    },
    "infra": {
      "agents": "Agents Management"
    },
    "plugins": {
      "neural_net": "Neural Network"
    },
    "data-lab": {
      "workshop": {
        "guide-line": "Guide Line",
        "statistics": "Statistics",
        "assignments": "Assignments"
      }
    }
  }
}
</i18n>
